
import CarsFilterService, { CarsFiltersServiceS } from '@/modules/cars/cars-filters.service';
import _ from 'lodash';
import { Vue, Component, Watch } from 'vue-property-decorator';
import ModalWrapper from '@/modules/common/components/modal-wrapper.vue';
import { inject } from '@/inversify';
import { KEY } from '@/inversify.keys';
import LoaderWrapper from '@/modules/common/components/loader-wrapper.vue';
import CategoryDraggable from '@/modules/cars-category-manager/components/category-draggable.vue';
import CarsCategoryManagerService, { CarsCategoryManagerServiceS } from '@/modules/cars-category-manager/cars-category-manager.service';
import type UserService from '@/modules/user/user.service';

@Component({
    components: {
        ModalWrapper,
        LoaderWrapper,
        CategoryDraggable,
    },
})

export default class CarsTypeMappingPopup extends Vue {
    @inject(KEY.UserService) private userService!: UserService;
    @inject(CarsCategoryManagerServiceS) private carsCategoryManagerService!: CarsCategoryManagerService;
    @inject(CarsFiltersServiceS) private carsFilterService!: CarsFilterService;

    private isExpanded = false;
    private categoryName = '';
    private activeCategory: string = '';
    private initializedCategories: Set<string> = new Set();
    private categoryDataSources: string[][] = [];

    @Watch('dataSources', { immediate: true })
    onChangeDataSources(newDataSources: string[], oldDataSources: string[]) {
        if (newDataSources && !_.isEqual(newDataSources, oldDataSources)) {
            this.categoryDataSources = newDataSources.map(category => newDataSources.filter(src => src !== category));
        }
    }

    get isReadOnly() {
        if (!this.userService.isAdmin) {
            return true;
        }
        return this.userService.isReadonly;
    }

    get categories() {
        const { allowedBrands, dataSources } = this.carsFilterService.settings;
        const { categories } = this.carsCategoryManagerService;
        const availableProviders = _.union(allowedBrands, dataSources);
        const availableCategories = _.intersection(availableProviders, categories);
        if (!this.activeCategory && availableCategories.length) {
            this.setActiveCategory(availableCategories[0]);
        }
        return availableCategories;
    }

    setActiveCategory(category: string) {
        this.activeCategory = category;
        this.initializedCategories.add(category);
    }

    getRawCategoryByCategory(categoryName: string) {
        return this.carsCategoryManagerService.getRawCategories(categoryName);
    }

    get dataSources() {
        const { currentCompany } = this.userService;
        return (this.carsCategoryManagerService.dataSources || [])
            .map(item => (item === 'Brand' && currentCompany ? currentCompany : item));
    }

    get handleChange(): boolean {
        return this.carsCategoryManagerService.isChange;
    }

    clickOutside(e: MouseEvent) {
        const ref = document.getElementById('car-category-context-menu') as HTMLDivElement;
        if (ref && (ref !== e.target && !ref.contains(e.target as Node | null))) {
            this.isExpanded = false;
            this.categoryName = '';
            document.body.removeEventListener('click', this.clickOutside);
        }
    }

    handleClick(data: string, event: PointerEvent) {
        this.isExpanded = !this.isExpanded;
        if (this.isExpanded) {
            this.categoryName = data;
            const element = document.getElementById('car-category-context-menu') as HTMLElement;
            // @ts-ignore
            element.style.left = `${event.target.parentNode.offsetLeft + event.target.parentNode.offsetWidth - 120}px`;
            document.body.addEventListener('click', this.clickOutside, true);
        } else {
            document.body.removeEventListener('click', this.clickOutside, true);
        }
    }

    handleOpenRename() {
        this.$router.push({ name: `${this.$route.name}.new-edit-car-category`, params: { carCategoryName: this.categoryName } });
    }

    handleOpenDelete() {
        this.$router.push({ name: `${this.$route.name}.delete-car-category`, params: { carCategoryName: this.categoryName } });
    }

    get showMenu() {
        return this.isExpanded;
    }

    async save() {
        await this.carsCategoryManagerService.save();
    }

    get newCategoryRoute() {
        const route = `${this.$router.currentRoute.path}/new-car-category`;
        return route;
    }
}

